import { getLogger } from '../common/logger';
import { simpleFetch } from '../common/simpleFetch';
import { getClosest, createElement, getElement, removeElement } from './utils';

const logger = getLogger();

const DEFAULT_BUTTON_CAPTION = 'Upload image';
const DEFAULT_FORM_FIELD = 'image';
const DEFAULT_DEL_TEXT = 'x';

export const PAGE_CLASSES = {
	BUTTON_CLASS: 'cloudinary-button',
	THUMBNAILS: 'cloudinary-thumbnails',
	THUMB_ITEM: 'cloudinary-thumbnail',
	THUMB_DEL: 'cloudinary-delete',
};

const getFieldName = (options) => options.fieldName || DEFAULT_FORM_FIELD;

const getPageElement = (element, options) => {
	let pageElement = element || options?.element;

	if (pageElement) {
		try {
			pageElement = getElement(pageElement);
		} catch (ex) {
			throw new Error("[Cloudinary.UploadWidget]: 'element' param must either be a valid HTMLElement or a selector string");
		}

		if (!pageElement || !pageElement.nodeType) {
			throw new Error("[Cloudinary.UploadWidget]: 'element' param must resolve to a valid HTMLElement");
		}
	}

	return pageElement;
};

const initUploadButton = (element, open, options) => {
	const button = createElement('a', { href: '#' }, options.buttonClass || PAGE_CLASSES.BUTTON_CLASS);
	button.innerHTML = options.buttonCaption || DEFAULT_BUTTON_CAPTION;

	if (element.parentNode) {
		element.parentNode.insertBefore(button, element.previousSibling);
	}

	button.addEventListener('click', (e) => {
		open();

		if (e.preventDefault) {
			e.preventDefault();
		}

		if (e.stopPropagation) {
			e.stopPropagation();
		}

		return false;
	});

	return button;
};

const revertUploadButton = (button) => {
	removeElement(button);
};

const addResultFormField = (result, form, options) => {
	const field = createElement(
		'input',
		{
			type: 'hidden',
			name: getFieldName(options),
		},
		null,
		{
			cloudinaryPublicId: result.public_id,
		}
	);

	field.value = `${[result.resource_type, result.type, result.path].join('/')}#${result.signature}`;

	try {
		field.dataset.cloudinary = JSON.stringify(result);
	} catch (ex) {
		logger.error('[all.pageIntegrations]: failed to add info as serialized data attribute');
	}

	form.appendChild(field);
};

const getForm = (element, options) => {
	let { form } = options;

	if (!form && element) {
		form = getClosest(element, 'form');
	}

	return form;
};

const addResultToForm = (result, element, options) => {
	let form = getForm(element, options);

	if (form) {
		// not removing existing inputs as new UW is working in queue mode
		form = getElement(form);

		if (form) {
			addResultFormField(result, form, options);
		}
	}
};

const getApiHost = (options) =>
	options.deleteHost ? options.deleteHost : `https://api${options.dev ? '-dev' : options.staging ? '-staging' : ''}.cloudinary.com`;

const removeElementsAfterDelete = (thumb, element, data, options) => {
	removeElement(thumb);

	const form = getForm(element, options);

	if (form) {
		const infoField = form.querySelector(`input[name="${getFieldName(options)}"][data-cloudinary-public-id="${data.public_id}"]`);

		if (infoField) {
			removeElement(infoField);
		}
	}
};

const handleDelete = (delBtn, thumb, element, data, options, actions) => {
	const doDelete = (e) => {
		const apiHost = getApiHost(options);
		const url = `${apiHost}/v1_1/${options.cloudName}/delete_by_token`;

		logger.log(`[all.pageIntegrations]:
        about to send delete request with token: ${data.delete_token} to : ${url}`);

		e.preventDefault();

		return simpleFetch(url, 'POST', { token: data.delete_token }, { 'Content-Type': 'application/json' }, { dontRead: true })
			.then((xhr) => {
				if (xhr.status === 200) {
					logger.log('[all.pageIntegrations]: successfully deleted file');

					delBtn.removeEventListener('click', doDelete);
					removeElementsAfterDelete(thumb, element, data, options);

					actions.triggerEvent('cloudinarywidgetdeleted', data);
				}
			})
			.catch((xhr) => {
				logger.warn(`[all.pageIntegrations]: failed to delete file with status: ${xhr.status}`);
			}); // returning the promise for test purposes
	};

	delBtn.addEventListener('click', doDelete);
};

const createThumbnailItem = (result, element, options, actions) => {
	const li = createElement('li', null, PAGE_CLASSES.THUMB_ITEM, {
		cloudinary: JSON.stringify(result),
	});

	let itemInfo;

	if (result.thumbnail_url) {
		itemInfo = createElement('img', { src: result.thumbnail_url });

		const onImgLoad = () => {
			li.classList.add('active');
			itemInfo.removeEventListener('load', onImgLoad);
		};

		itemInfo.addEventListener('load', onImgLoad);
	} else {
		itemInfo = createElement('span');
		itemInfo.textContent = result.public_id;
	}

	li.appendChild(itemInfo);

	if (result.delete_token) {
		const delBtn = createElement('a', { href: '#' }, PAGE_CLASSES.THUMB_DEL);
		delBtn.textContent = DEFAULT_DEL_TEXT;
		li.appendChild(delBtn);

		handleDelete(delBtn, li, element, result, options, actions);
	}

	return li;
};

const processThumbnail = (result, element, options, actions) => {
	if (options.thumbnails !== false && (options.thumbnails || element)) {
		// if thumbnails isnt explicitly false then do show thumbnails
		let listExists = true;
		let ul = getElement(`${options.thumbnails || ''} .${PAGE_CLASSES.THUMBNAILS}`);

		if (!ul) {
			listExists = false;
			ul = createElement('ul', null, PAGE_CLASSES.THUMBNAILS);
		}

		ul.appendChild(createThumbnailItem(result, element, options, actions));

		if (!listExists) {
			logger.log('[all.pageIntegrations]: adding thumbnails list to dom');

			const thumbsContainer = options.thumbnails && getElement(options.thumbnails);

			if (thumbsContainer) {
				thumbsContainer.appendChild(ul);
			} else if (element) {
				element.insertAdjacentElement('afterend', ul);
			}
		}
	}
};

const processUploadResult = (result, element, options, actions) => {
	addResultToForm(result, element, options);
	processThumbnail(result, element, options, actions);
};

const clearThumbnails = (options) => {
	if (options.thumbnails !== false) {
		const ul = getElement(`${options.thumbnails || ''} .${PAGE_CLASSES.THUMBNAILS}`);

		if (ul) {
			removeElement(ul);
		}
	}
};

export { getPageElement, initUploadButton, processUploadResult, revertUploadButton, clearThumbnails };
