import { setLoggerDefaultLevel, LOG_LEVELS } from './common/logger';
import { UPLOAD_SOURCES } from './common/widgetConsts';
import createWidget from './global/createWidget';
import applyPageStyles from './global/pageStyles';
import getAppUrl from './getAppUrl';

const widgetAppUrlFromScript = getAppUrl();

((win) => {
	// eslint-disable-next-line no-undef
	const widgetVersion = VERSION;
	const globals = {
		cloudName: null,
		apiKey: null,
	};

	const $ = win.jQuery ? win.jQuery : win.$ && win.$.fn && win.$.fn.jquery ? win.$ : null;
	const debug = win.location.search.indexOf('debug=true') > -1;
	const dev = win.location.search.indexOf('dev=true') > -1;

	setLoggerDefaultLevel(debug ? LOG_LEVELS.LOG : LOG_LEVELS.WARN);

	applyPageStyles();

	const getWidgetOptions = (options) => ({
		...globals,
		dev,
		debug,
		...options,
		widgetVersion,
		widgetAppUrlFromScript,
		$,
	});

	//* ********************************************* EXTERNAL API **********************************************
	// eslint-disable-next-line no-multi-assign
	const cld = (win.cloudinary = win.cloudinary || {});

	cld.applyUploadWidget = (element, options, widgetCallback) => createWidget(getWidgetOptions(options), widgetCallback, element);

	cld.createUploadWidget = (options, widgetCallback) => cld.applyUploadWidget(null, options, widgetCallback);

	cld.openUploadWidget = (options, widgetCallback) => cld.createUploadWidget(options, widgetCallback).open();

	cld.setCloudName = (name) => {
		globals.cloudName = name;
	};

	cld.setAPIKey = (key) => {
		globals.apiKey = key;
	};

	cld.WIDGET_SOURCES = { ...UPLOAD_SOURCES };

	cld.WIDGET_VERSION = widgetVersion;

	if ($) {
		$.fn.cloudinary_upload_widget = function (options, widgetCallback) {
			cld.applyUploadWidget($(this)[0], options, widgetCallback);
		};
	}
})(self);
