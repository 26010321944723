const camelCapitalize = (name, index) => (index === 0 ? name : name.substr(0, 1).toUpperCase() + name.substr(1));

const hasSnake = (name) => name.indexOf('_') > 0; // ignores names that start with _

const camelizeFromSnake = (name) => name.split('_').map(camelCapitalize).join('');

const camelizeSnakeParams = (obj) =>
	Object.keys(obj).reduce((res, key) => {
		const newKey = hasSnake(key) ? camelizeFromSnake(key) : key;
		res[newKey] = obj[key];
		return res;
	}, {});

export { camelizeFromSnake, camelizeSnakeParams };
