export default {
	MINIFIED_WIDTH: 500,
	MINIFIED_HEIGHT: 55,
	MINIFIED_POSITION: 'right:35px',
	MINIFIED_MEDIA_QUERY: '(min-width: 767px)',
};

export const DISPLAY_VALUES = {
	HIDDEN: 'hidden',
	SHOWN: 'shown',
};
