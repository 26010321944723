import { camelizeSnakeParams } from '../common/camelizeSnakeParams';
import { isFunction, warn } from './utils';

const DEPRECATED_PARAMS = ['keepWidgetOpen', 'stylesheet'];
const { toString } = Object.prototype;
const objString = '[object Object]';

const warnOnUnusedParams = (options) =>
	DEPRECATED_PARAMS.forEach((param) => {
		if (typeof options[param] !== 'undefined') {
			warn(`Cloudinary.UploadWidget - '${param}' is no longer used in this version.`);
		}
	});

export const processParams = (params) => (params ? camelizeSnakeParams(params) : {});

export default (params, element) => {
	params = params || {};

	if (toString.call(params) !== objString) {
		throw new Error('[Cloudinary.UploadWidget]: widget options must be a valid Object');
	}

	const wOpts = processParams(params);

	wOpts.secure = wOpts.secure !== false; // default to secure
	wOpts.requirePrepareParams = !!wOpts.prepareUploadParams || !!wOpts.uploadSignature; // uploadSignature for backwards compatibility
	wOpts.useTagsCallback = isFunction(wOpts.getTags);
	wOpts.useUploadPresetsCallback = isFunction(wOpts.getUploadPresets);
	wOpts.usePreBatchCallback = isFunction(wOpts.preBatch);
	wOpts.useMetadataCallback = isFunction(wOpts.getMetadataSchema);
	wOpts.inlineMode = !!wOpts.inlineContainer; // only accept inline mode on init

	wOpts.fieldName = params.fieldName || (element && element.getAttribute('name')) || null;

	warnOnUnusedParams(wOpts);

	return wOpts;
};
