export const MANDATORY_PARAMS = ['cloudName', 'uploadPreset', 'apiKey'];

export const BEHAVIOR_PARAMS = [
	'sources',
	'secure',
	'defaultSource',
	'showAdvancedOptions',
	'showCompletedButton',
	'showUploadMoreButton',
	'multiple',
	'maxFiles',
	'autoMinimize',
	//result of 'inlineContainer' param
	'inlineMode',
	'singleUploadAutoClose',
];

export const CROP_PARAMS = [
	'cropping',
	'croppingAspectRatio',
	'croppingDefaultSelectionRatio',
	'croppingShowDimensions',
	'croppingCoordinatesMode',
	'croppingShowBackButton',
	'croppingValidateDimensions',
	'showSkipCropButton',
];

export const UPLOAD_PARAMS = [
	'publicId',
	'folder',
	'assetFolder',
	'tags',
	'uploadPreset',
	'resourceType',
	'context',
	'uploadSignatureTimestamp',
	'useFilename',
	'uniqueFilename',
	'invalidate',
	'overwrite',
	'auditContext',
	'qualityAnalysis',
	'metadata',
	'displayName',
	'useAssetFolderAsPublicIdPrefix',
	'publicIdPrefix',
	'detection',
	'onSuccess',
];

export const CLIENT_SIDE_PARAMS = [
	//result of 'prepareUploadParams' or 'uploadSignature' params
	'requirePrepareParams',
	//result of 'getTags' param
	'useTagsCallback',
	//result of 'getUploadPresets' param
	'useUploadPresetsCallback',
	//result of 'preBatch' param
	'usePreBatchCallback',
	//result of 'getMetadataSchema' param
	'useMetadataCallback',
	'clientAllowedFormats',
	'maxImageWidth',
	'maxImageHeight',
	'validateMaxWidthHeight',
	'minImageHeight',
	'minImageWidth',
	'maxImageWidth',
	'maxImageHeight',
	'maxFileSize',
	'maxImageFileSize',
	'maxVideoFileSize',
	'maxRawFileSize',
	'maxChunkSize',
	'thumbnails',
	'thumbnailTransformation',
	'secureDistribution', // undocumented - used for thumbnail generation
	'inlineContainer',
	'frameContainer', // undocumented (allows putting modal frame into a container instead of the body which is the default)
	'newTlsDomain',
	'tabInsideWidget',
	'concurrentUploads',
];

export const ADVANCED_PARAMS = ['showPoweredBy', 'showInsecurePreview', 'encryption'];

export const CUSTOMIZATION_PARAMS = ['theme', 'text', 'language', 'styles'];

// undocumented params
export const INTERNAL_PARAMS = ['uploadPrefix', 'debug'];

export const THIRD_PARTY_PARAMS = [
	'googleApiKey',
	'dropboxAppKey',
	'facebookAppId',
	'instagramServer',
	'shutterstockServer',
	'istockServer',
	'gettyServer',
	'googleDriveClientId',
	'searchBySites',
	'searchByRights',
];

export const PAGE_INTEGRATION_PARAMS = [
	'buttonCaption',
	'buttonClass',
	'queueViewPosition',
	'controlVpMeta',
	'fieldName',
	'frameZIndex',
	'widgetHost',
	'thumbnails',
	'thumbnailTransformation',
];

export const CLIENT_CALLBACKS = ['getUploadPresets'];

export const NON_UPLOADER_PARAMS = [
	// params that will not cause the upload field to re-render
	'sources',
	'secure',
	'defaultSource',
	'uploadHost',
]
	.concat(THIRD_PARTY_PARAMS)
	.concat(CUSTOMIZATION_PARAMS)
	.concat(ADVANCED_PARAMS)
	.concat(INTERNAL_PARAMS)
	.concat(PAGE_INTEGRATION_PARAMS)
	.concat(CROP_PARAMS);
