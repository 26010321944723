import { WIDGET_VIEW_TYPES, INLINE_HEIGHT } from '../common/widgetConsts';
import consts from './consts';
import { createElement, appendElement, getElement, addStyles, showElement, hideElement, removeElement } from './utils';

// todo: change use of self.top to check if it can be accessed (blocked if cross domain)

const POSITION_RGX = /(left|right)(?::([0-9a-z]*))?$/;
const VP_META_SELECTOR = 'head meta[name="viewport"]';

const getUrlParams = (options) => {
	const params = [];

	if (options.debug) {
		params.push('debug=true');
	}

	if (options.dev) {
		params.push('dev=true');
	}

	if (options.cloudName) {
		params.push(`cloudName=${options.cloudName}`);
	}

	params.push(`pmHost=${self.location.protocol}//${self.location.host}`);

	return params;
};

export const frameController = (getOptions, widgetHost, resolve) => {
	// exported for testing purposes
	const initOptions = getOptions(); // we dynamically get options because widget.update() can change them

	const qViewPositionInfo = {
		raw: consts.MINIFIED_POSITION, // default mini queue position
		side: null, // "right",
		offset: null, // "35px",
	};

	const widgetFrame = createElement(
		'iframe',
		{
			frameborder: 'no',
			allow: 'camera',
			width: '100%',
			height: '100%',
			title: 'Upload Widget',
		},
		null,
		{ test: 'uw-iframe' }
	);

	addStyles(widgetFrame, {
		border: 'none',
		background: 'transparent',
	});

	const widgetMediaQuery = window.matchMedia(consts.MINIFIED_MEDIA_QUERY);
	const inlineElement = initOptions.inlineContainer && getElement(initOptions.inlineContainer);
	const frameContainer = initOptions.frameContainer && getElement(initOptions.frameContainer);
	const frameContainerOrgPosition = frameContainer?.style?.position;

	if (inlineElement) {
		addStyles(inlineElement, {
			minHeight: `${INLINE_HEIGHT}px`,
			overflowX: 'hidden', // TODO: need to check about responsive
		});
	}

	if (frameContainer) {
		addStyles(frameContainer, { position: 'relative' });
	}

	let bodyOverflowMode = null;
	let hasViewPortMetaTag = false;
	let viewPortContent = '';
	let isOpen = false; // true when open was called
	let isReady = false; // true when the iframe has loaded
	let isShowing = false; // true when the iframe is showing
	let isFullScreen = false;
	let topDoc;

	const setViewPortMetaTag = (noScroll) => {
		const options = getOptions();

		if (options.controlVpMeta === true) {
			if (noScroll) {
				let vpMeta = getElement(VP_META_SELECTOR, self.top);

				if (!vpMeta) {
					vpMeta = createElement('meta', { name: 'viewport' }, null, null, self.top);
					topDoc.head.appendChild(vpMeta);
				}

				vpMeta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'; // ensure no zoom by user or on input focus
			} else {
				// revert back to how it was before
				const vpMeta = getElement(VP_META_SELECTOR, self.top);

				if (hasViewPortMetaTag && vpMeta) {
					// there was a viewport meta already before the widget
					vpMeta.content = viewPortContent; // revert to original value
				} else if (vpMeta) {
					// no viewport meta before the widget
					topDoc.head.removeChild(vpMeta);
				}
			}
		}
	};

	const freezeWebkitBodyScroll = (e) => {
		e.preventDefault();
	};

	const setMobileTouchMoveHandling = (noScroll) => {
		if (noScroll) {
			topDoc.addEventListener('touchmove', freezeWebkitBodyScroll);
		} else {
			topDoc.removeEventListener('touchmove', freezeWebkitBodyScroll);
		}
	};

	/**
	 * disable scroll on body when widget is in full screen, otherwise, revert to the original value
	 */
	const setBodyOverflow = () => {
		if (!inlineElement && !frameContainer) {
			const noScroll = isShowing && isFullScreen;

			if (topDoc.body) {
				// for flow :(
				bodyOverflowMode = bodyOverflowMode === null ? topDoc.body.style.overflow : bodyOverflowMode; // save the overflow style of the page

				topDoc.body.style.overflow = noScroll ? 'hidden' : bodyOverflowMode;
			}

			setMobileTouchMoveHandling(noScroll);
			setViewPortMetaTag(noScroll);
		}
	};

	/**
	 *  only hides the frame (in case its open&ready) without setting its state to not open
	 */
	const hideWidget = () => {
		if (isOpen && isReady) {
			hideElement(widgetFrame);
			isShowing = false;
			setBodyOverflow();
		}
	};

	/**
	 *  only shows the frame (in case its open&ready) without setting its state to open
	 */
	const showWidget = () => {
		if (isOpen && isReady) {
			showElement(widgetFrame);
			isShowing = true;
			setBodyOverflow();
			widgetFrame.focus();
		}
	};

	const insertFrame = () => {
		appendElement(widgetFrame, inlineElement || frameContainer || undefined);

		if (!inlineElement) {
			topDoc.addEventListener('keyup', (e) => {
				if (e.keyCode === 27) {
					hideWidget();
				}
			});
		}
	};

	const positionMiniView = (isMobile) => {
		const options = getOptions();

		if (
			(options.queueViewPosition && options.queueViewPosition !== qViewPositionInfo.raw) || // got new value
			!qViewPositionInfo.side ||
			!qViewPositionInfo.offset
		) {
			// hasnt set info before = first time
			qViewPositionInfo.raw = options.queueViewPosition || qViewPositionInfo.raw;

			const posMatch = POSITION_RGX.exec(qViewPositionInfo.raw);

			if (posMatch) {
				qViewPositionInfo.side = posMatch[1];
				qViewPositionInfo.offset = posMatch[2] || '0';
			} else {
				throw new Error(`queueViewPosition param (${options.queueViewPosition || ''}) is invalid. (valid ex: "right:35px")`);
			}
		}

		let frameStyles;

		if (isMobile) {
			frameStyles = {
				left: '0px',
				right: '0px',
			};
		} else if (qViewPositionInfo.side === 'left') {
			frameStyles = {
				left: qViewPositionInfo.offset || '',
				right: '',
			};
		} else {
			frameStyles = {
				right: qViewPositionInfo.offset || '',
				left: '',
			};
		}

		addStyles(widgetFrame, frameStyles);
	};

	const showFrameMiniQueue = (isMobile) => {
		const width = `${Math.min(consts.MINIFIED_WIDTH, window.innerWidth)}px`;
		const height = `${consts.MINIFIED_HEIGHT}px`;

		addStyles(widgetFrame, {
			width: isMobile ? '100%' : width,
			bottom: isMobile ? '0px' : '5px',
			height,
			top: '',
		});

		positionMiniView(isMobile);

		isFullScreen = false;
		setBodyOverflow();
	};

	const showFrameFullScreen = () => {
		let frameStyles;

		frameStyles = inlineElement
			? {
					height: `${INLINE_HEIGHT}px`,
					width: '100%',
			  }
			: {
					width: '100%',
					height: '100%',
					top: '0px',
					left: '0px',
					bottom: '',
			  };

		addStyles(widgetFrame, frameStyles);
		isFullScreen = true;

		setBodyOverflow();
	};

	const showUploaderFrame = () => {
		showWidget();
		showFrameFullScreen();
	};

	const mediaQueryHandler = (mq) => {
		showFrameMiniQueue(!mq.matches);
	};

	const handleWidgetViewTypeChange = (info) => {
		widgetMediaQuery.removeListener(mediaQueryHandler);

		// eslint-disable-next-line default-case
		switch (info.type) {
			case WIDGET_VIEW_TYPES.INITIAL:
			case WIDGET_VIEW_TYPES.EXPANDED:
				showFrameFullScreen();
				break;
			case WIDGET_VIEW_TYPES.MINI:
				showFrameMiniQueue(!widgetMediaQuery.matches);
				widgetMediaQuery.addListener(mediaQueryHandler);
				break;
		}
	};

	const postMessage = (data) => widgetFrame.contentWindow.postMessage(data, widgetHost);

	const isFrameReady = () => isReady;

	const isWidgetOpen = () => isOpen;

	const isWidgetShowing = () => isOpen && isShowing;

	const isWidgetMinimized = () => isWidgetOpen() && !isFullScreen;

	const close = () => {
		hideWidget();
		isOpen = false;
	};

	const open = (showOptions) => {
		isOpen = true;

		if (isReady) {
			if (!showOptions?.hidden) {
				showUploaderFrame();

				if (showOptions?.files) {
					hideElement(widgetFrame); // hide in case widget iframe already showing - to avoid widget showing large before switching to queue mode
				}
			}
		}
	};

	const optionsUpdated = () => {
		if (isOpen && isReady && !isFullScreen) {
			// need to check if mini view should be moved based on new options
			showFrameMiniQueue(!widgetMediaQuery.matches);
		}
	};

	const remove = () => {
		removeElement(widgetFrame);

		if (frameContainer && frameContainerOrgPosition) {
			addStyles(frameContainer, { position: frameContainerOrgPosition });
		}
	};

	const onFrameLoaded = () => {
		widgetFrame.removeEventListener('load', onFrameLoaded);

		isReady = true; // allow the widget to be shown after sending the init command

		resolve({
			// the API exposed by this controller
			open,
			close,
			showWidget,
			hideWidget,
			isFrameReady,
			isWidgetOpen,
			isWidgetMinimized,
			isWidgetShowing,
			postMessage,
			handleWidgetViewTypeChange,
			optionsUpdated,
			remove,
		});

		showUploaderFrame();
	};

	const initializeViewPortMeta = () => {
		if (getOptions().controlVpMeta === true) {
			const vpMeta = getElement(VP_META_SELECTOR, self.top);

			if (vpMeta) {
				viewPortContent = vpMeta.content; // save value so we can return to it
				hasViewPortMetaTag = true;
			}
		}
	};

	const getTopDoc = () => {
		let doc = self.document;

		try {
			doc = self.top.document;
		} catch (ex) {
			// fail silently - cross domain protection will not allow to work with a parent from different origin
		}

		return doc;
	};

	const init = (options) => {
		topDoc = getTopDoc();

		const urlParams = getUrlParams(options);
		const url = `${widgetHost}?${urlParams.join('&')}`;

		widgetFrame.setAttribute('src', url);

		hideElement(widgetFrame);

		addStyles(widgetFrame, {
			position: inlineElement ? null : frameContainer ? 'absolute' : 'fixed',
			zIndex: inlineElement ? null : options.frameZIndex || '1000000',
		});

		widgetFrame.addEventListener('load', onFrameLoaded);

		initializeViewPortMeta();
		insertFrame();
	};

	init(initOptions);
};

export default (getOptions, widgetHost) => new Promise(frameController.bind(null, getOptions, widgetHost));
