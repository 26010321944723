import { createElement, getElement, removeElement, appendElement, addStyles, showElement, hideElement } from '../common/domElements';

const HAS_FILE_READ_SUPPORT = 'FileReader' in self && 'FileList' in self && 'Blob' in self;

const isString = (subject) => typeof subject === 'string';

const isFunction = (subject) => typeof subject === 'function';

const isFile = (obj) => HAS_FILE_READ_SUPPORT && (obj instanceof File || obj.toString() === '[object File]');

const contains = (optParent, optChild) => {
	const res = optParent.compareDocumentPosition(optChild);
	return !!(res & Node.DOCUMENT_POSITION_CONTAINED_BY); // bitwise check to see if res includes the contained by bit
};

const getClosest = (element, selector) => {
	let parent = null;

	if (element.closest) {
		parent = element.closest(selector);
	} else {
		// look for a parent in the doc
		const matches = self.document.querySelectorAll(selector);

		if (matches && matches.length) {
			for (let i = 0; i < matches.length; i++) {
				// IE11 does not support Array.prototype.find & cant use for-of because it uses Symbol.iterator
				const match = matches[i];
				if (contains(match, element)) {
					parent = match;
					break;
				}
			}
		}
	}

	return parent;
};

// eslint-disable-next-line no-console
const warn = (...args) => console.warn(...args);

export { isString, isFunction, isFile, getClosest, createElement, getElement, removeElement, appendElement, addStyles, showElement, hideElement, warn };
